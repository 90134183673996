import React, { useEffect, useState } from 'react';
import './withdraw.css'
import toast, { Toaster } from 'react-hot-toast';
import { Userbalance } from '../../utils/balance';
import useAxios from '../../utils/useAxios';
import { base_url } from '../config/config';
const Send = () => {
    const [amount, setAmount] = useState(0);
    const [loading, setLoading] = useState(false);
    const [data, setdata] = useState([])
    const [balance, setBalance] = useState(0)
    const [remaBalance, setRemBalance] = useState(0)
    const [showAmount, setShowAmount] = useState(0)
    const [disable, setDisable] = useState(false)
    const [wallet, setWallet] = useState('')
    const [fee, setFee] = useState(0)
    const api = useAxios()
    const [userdata, setUseradat] = useState(null)
    const fetchBalance = async () => {
        let data = await Userbalance()
        setBalance(data.balance)

    }
    const fetchuser = async (e) => {

        try {
            let user = await api.post(`${base_url}/get_user/`, { wallet: e.target.value })
            setUseradat(user.data)
        } catch (error) {
            console.log(error)

        }
    }


    const handleSubmit = async () => {
        // Set loading state to true during the request
        try {
            const total = parseFloat(amount) + parseFloat(fee)
            if (total <= balance) {
                setLoading(true);
                setDisable(true)
                const response = await api.post(`${base_url}/send/`, { amount: amount, wallet: wallet });
                console.log(response.data);
                setLoading(false);
                toast.success("withdraw request successfull")
                fetchBalance()
                setDisable(false)
            }
            else {
                toast.error('not enough balance to pay the transaction fee')
            }
        } catch (error) {
            toast.error(`${"internal server error"}`, "error")
            setLoading(false); // handle error
            setDisable(false)
            console.log(error)
        }
    }


    const calulate_rem_balance = (e) => {
        const rem_bal = parseFloat(balance) - parseFloat(e.target.value)
        console.log(typeof (e.target.value))
        if (e.target.value !== '') {
            const AmountCuted = parseFloat(e.target.value)
            setShowAmount(AmountCuted)
            setRemBalance(rem_bal.toFixed(2))
            setFee(parseFloat(e.target.value) * 0.25 / 100)
        } else {
            setShowAmount(0)
            setRemBalance(0)
        }
        if (rem_bal < 0 || parseFloat(e.target.value) < parseFloat(25)) {
            setDisable(true)
            setRemBalance(0)
        }
        else {
            setDisable(false)
        }
    }


    useEffect(() => {
        fetchBalance()
    }, [])
    return (

        <div className="body-w" style={{ marginTop: '50px', marginBottom: '70px' }}>
            <div className="form-w">
                <ol>
                    <li>Add the deposit address provided on the deposit page From your user.</li>
                    <li>Scroll down to find the option to select the recipient's username and image.</li>
                    <li>Confirm the details and proceed with the transaction.</li>
                </ol>
                <div className='d-flex justify-content-between'>
                    <div>
                        <p>BALANCE</p>
                    </div>
                    <div>
                        <p><b>{balance.toFixed(2)}$</b></p>
                    </div>
                </div>
                <div className="input-w">
                    <div className="inputBox-w">
                        <input type="text" placeholder="Enter Wallet" value={wallet} onInput={(e) => {
                            fetchuser(e)
                            setWallet(e.target.value)
                        }} />
                    </div>
                    <div className="inputBox-w">
                        <input type="number" placeholder="Enter Amount" value={amount} onInput={(e) => {

                            setAmount(e.target.value)
                            calulate_rem_balance(e)
                        }} />
                    </div>
                    <div className="inputBox-w">
                        <input type="submit" defaultValue="Submit" disabled={disable} onClick={handleSubmit} />
                    </div>
                </div>
                <div className='d-flex justify-content-between' style={{ marginTop: 10 }}>
                    <div>
                        <p>AMOUNT</p>
                    </div>
                    <div>
                        <p className='text-danger'>-{showAmount}$</p>
                    </div>
                </div>
                <div className='d-flex justify-content-between' style={{ marginTop: 10 }}>
                    <div>
                        <p>Fee</p>
                    </div>
                    <div>
                        <p className='text-danger'>-{fee}$</p>
                    </div>
                </div>
                <div className='d-flex justify-content-between' style={{ marginTop: 10 }}>
                    <div>
                        <p>REMAINDER</p>
                    </div>
                    <div>
                        <p className='text-success'>{remaBalance}$</p>
                    </div>
                </div>
                <hr />
                <div>
                    {userdata !== null ?

                        <div className='d-flex justify-content-between' style={{ marginTop: 10 }}>
                            <div>
                                <img style={{ height: 50, width: 50, borderRadius: '50%', border: '3px solid white' }} src={userdata?.image} alt="" />
                            </div>
                            <div>
                                {userdata?.username}
                            </div>
                        </div> :
                        <p>no user yet</p>

                    }
                </div>

            </div>
            <Toaster />
        </div>


    );
}

export default Send;
