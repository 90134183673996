import useAxios from "./useAxios";
import { base_url } from "../components/config/config";
export const UserProfile = async () => {
    const api = useAxios()
    try {
        const netuser = await api.get(`${base_url}/netuser_detail/`)
        return netuser?.data
    } catch (error) {
        return {}
    }


}

