import React, { useEffect, useState } from 'react';
import { Pie } from 'react-chartjs-2';
import { Chart, ArcElement } from 'chart.js';

Chart.register(ArcElement);
const coins = [
  { nm: 'Bitcoin', bg: '#F7931A' },
  { nm: 'Ethereum', bg: '#627EEA' },
  { nm: 'Ripple', bg: '#1AAB9B' },
  { nm: 'Litecoin', bg: '#B6B6B6' },
  { nm: 'Cardano', bg: '#0D98BA' },
  { nm: 'Stellar', bg: '#08BDBA' },
  { nm: 'Dogecoin', bg: '#CEC3D6' }
]
const Detail = ({ order }) => {
  const [tradeData, setTradeData]=useState([15, 16, 17, 15, 14, 15, 8])
  // Data for the pie chart
  const chartData = {
    labels: ['Bitcoin', 'Ethereum', 'Ripple', 'Litecoin', 'Cardano', 'Stellar', 'Dogecoin'],
    datasets: [
      {
        data: tradeData,
        backgroundColor: ['#F7931A', '#627EEA', '#1AAB9B', '#B6B6B6', '#0D98BA', '#08BDBA', '#CEC3D6'],
      },
    ],
  };
  const startingTime = new Date(order?.starting_time * 1000).toLocaleString();
  const endingTime = new Date(order?.ending_time * 1000).toLocaleString();
  function generateRandomArray() {
    let length=7
    let arr = [];
    let total = 100;

    for (let i = 0; i < length - 1; i++) {
        let num = (Math.random() * (total / (length - i))).toFixed(2);
        arr.push(parseFloat(num));
        total -= num;
    }

    // Adjust the last number to ensure the sum is 100
    arr.push(parseFloat(total.toFixed(2)));

    setTradeData(arr)
}
useEffect(()=>{
  generateRandomArray()
},[order])
  if (order != null) {
    return (
      <>
        <div style={{ backgroundColor: "#1e1e1e", padding: 25 }}>
          <div className="d-flex justify-content-center">
            <div className="card" style={{ width: '20rem', backgroundColor: "#1e1e1e" }}>
              <ul className="list-group list-group-flush bg-trs" style={{ listStyle: 'none', color: '#3af4f8' }}>
                <li className="bg-trs list-group-item">
                  <div className="d-flex justify-content-between">
                    <div>Amount</div>
                    <div>${order.amount?.toFixed(4)}</div>
                  </div>
                </li>
                <li className="bg-trs list-group-item">
                  <div className="d-flex justify-content-between">
                    <div>Total Income</div>
                    <div>${order.commission?.toFixed(4)}</div>
                  </div>
                </li>
                <li className="bg-trs list-group-item">
                  <div className="d-flex justify-content-between">
                    <div>Started On</div>
                    <div>{startingTime}</div>
                  </div>
                </li>
                <li className="bg-trs list-group-item">
                  <div className="d-flex justify-content-between">
                    <div>Ended On</div>
                    <div>{endingTime}</div>
                  </div>
                </li>
                <li className="bg-trs list-group-item">
                  <div className="d-flex justify-content-between">
                    <div>Personal Income</div>
                    <div>{order.personal_commision?.toFixed(4)}</div>
                  </div>
                </li>
                <li className="bg-trs list-group-item">
                  <div className="d-flex justify-content-between">
                    <div>Market Commission </div>
                    <div>{order?.market_comission?.toFixed(4)}</div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div className="d-flex justify-content-center">
            <div className="card my-2" style={{ width: '270px', backgroundColor: "#1e1e1e" }}>
              <div style={{ margin: 20 }}>
                <Pie data={chartData} height={200} width={200} />
                <div>
                  {coins.map((i, j) => {
                    return (
                      <div className='d-flex justify-content-start' key={j}>
                        <div style={{ backgroundColor: i.bg,height:20,width:20, borderRadius:'50%' }}></div> <span style={{marginLeft:'5px'}}>{i.nm}-{tradeData[j]}%</span>
                      </div>
                    )

                  })}

                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  } else {
    <p className='text-center text-white'>
      no data
    </p>
  }
};

export default Detail;