import React from 'react';
import './Support.css'
const Support = () => {
    return (
        <div>
            <div className="container-s" style={{ margin: 30 }} >
                <div>
                    <h1>Need Suppor?</h1>
                    <p style={{ textAlign: 'center' }} className="platform-description">
                        Welcome to our crypto networking platform! Whether you're a seasoned user
                        or just getting started, we're here to help. Explore the following services
                        to find the support you need.
                    </p>
                    <div className="row">
                        <div className="service">
                            <ion-icon name="help-circle-outline"></ion-icon>
                            <h2>FAQ</h2>
                            <p>
                                Find answers to frequently asked questions about our platform. Learn
                                how to navigate, manage your account, and more.
                            </p>
                        </div>
                        <div className="service">
                            <ion-icon name="chatbubble-ellipses-outline" />
                            <h2>Contact Support</h2>
                            <p>
                                Reach out to our dedicated support team. We're available 24/7 to assist
                                you with any issues or inquiries you may have.
                            </p>
                        </div>
                        <div className="service">
                            <ion-icon name="logo-web-component"></ion-icon>
                            <h2>About Us</h2>
                            <p>
                                Join us on this exciting journey as we redefine the way people engage with the
                                crypto space. Together, we're shaping the future of decentralized connectivity,
                                empowering individuals to thrive in the dynamic landscape of digital assets.
                            </p>
                        </div>
                        <div className="service">
                            <ion-icon name="document-lock-outline"></ion-icon>
                            <h2>Privacy Policy</h2>
                            <p>
                                Rest assured that your information is handled with the utmost care and in compliance
                                with relevant data protection regulations. If you have any questions or concerns
                                regarding your privacy, please don't hesitate to contact our support team.
                            </p>
                        </div>
                        <div className="service">
                            <ion-icon name="compass-outline"></ion-icon>
                            <h2>Oppurtunities</h2>
                            <p>
                                Explore exciting opportunities to be part of our dynamic and innovative team.
                                We believe in fostering talent and providing a platform for growth and creativity.
                                Join us on our journey to make a meaningful impact in the crypto community.
                            </p>
                        </div>
                        <div className="service" style={{ overflow: 'auto', marginBottom: "100px" }}>
                            <i className="fas fa-money-check-alt" />
                            <h2>Whats New?</h2>
                            <p>
                                Explore new functionalities, updated security measures, and exciting announcements
                                that shape the future of our platform. Your feedback is valuable as we continue to evolve
                                and provide cutting-edge solutions in the crypto space.
                            </p>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
}

export default Support;