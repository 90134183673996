import React, { useEffect, useState } from 'react';
import './withdraw.css'
import toast,{Toaster} from 'react-hot-toast';
import { Userbalance } from '../../utils/balance';
import useAxios from '../../utils/useAxios';
import { base_url } from '../config/config';
const Withdraw = () => {
    const [amount, setAmount] = useState(0);
    const [loading, setLoading] = useState(false);
    const [data, setdata] = useState([])
    const [balance, setBalance] = useState(0)
    const [remaBalance, setRemBalance] = useState(0)
    const [showAmount, setShowAmount] = useState(0)
    const [disable, setDisable] = useState(false)
    const [wallet, setWallet] = useState('')
    const [fee,setFee]=useState(0)
    const api = useAxios()
    const fetchBalance = async () => {
        let data = await Userbalance()
        setBalance(data.balance)

    }


    const handleSubmit = async () => {
        // Set loading state to true during the request
        try {
        const total=parseFloat(amount)+parseFloat(fee)
        if (total<=balance) {
            setLoading(true);
            setDisable(true)
            const response = await api.post(`${base_url}/withdraw/`, { amount: amount });
            console.log(response.data);
            setLoading(false);
            toast.success("withdraw request successfull")
            fetchBalance()
            setDisable(false)}
            else{
                toast.error('not enough balance to pay the transaction fee')
            }
        } catch (error) {
            toast.error(`${"internal server error"}`, "error")
            setLoading(false); // handle error
            setDisable(false)
            console.log(error)
        }
    }


    const calulate_rem_balance = (e) => {
        const rem_bal = parseFloat(balance) - parseFloat(e.target.value)
        console.log(typeof (e.target.value))
        if (e.target.value !== '') {
            const AmountCuted = parseFloat(e.target.value) 
            setShowAmount(AmountCuted)
            setRemBalance(rem_bal.toFixed(2))
            setFee(parseFloat(e.target.value) * 2 / 100)
        } else {
            setShowAmount(0)
            setRemBalance(0)
        }
        if (rem_bal < 0 || parseFloat(e.target.value) < parseFloat(0)) {
            setDisable(true)
            setRemBalance(0)
        }
        else {
            setDisable(false)
        }
    }

    useEffect(() => {
        fetchBalance()
    }, [])
    return (

        <div className="body-w">

            <div className="form-w">
                <div className='d-flex justify-content-between'>
                    <div>
                        <p>BALANCE</p>
                    </div>
                    <div>
                        <p><b>{balance.toFixed(2)}$</b></p>
                    </div>
                </div>
                <div className="input-w">
                    <div className="inputBox-w">
                        <input type="number" placeholder="Enter Amount" value={amount} onInput={(e) => {

                            setAmount(e.target.value)
                            calulate_rem_balance(e)
                        }} />
                    </div>
                    <div className="inputBox-w">
                        <input type="submit" defaultValue="Submit"  disabled={disable} onClick={handleSubmit}/>
                    </div>
                </div>
                <div className='d-flex justify-content-between' style={{ marginTop: 10 }}>
                    <div>
                        <p>AMOUNT</p>
                    </div>
                    <div>
                        <p className='text-danger'>-{showAmount}$</p>
                    </div>
                </div>
                <div className='d-flex justify-content-between' style={{ marginTop: 10 }}>
                    <div>
                        <p>Fee</p>
                    </div>
                    <div>
                        <p className='text-danger'>-{fee}$</p>
                    </div>
                </div>
                <div className='d-flex justify-content-between' style={{ marginTop: 10 }}>
                    <div>
                        <p>REMAINDER</p>
                    </div>
                    <div>
                        <p className='text-success'>{remaBalance}$</p>
                    </div>
                </div>
                <hr />
                <div className='d-flex justify-content-between' style={{ marginTop: 10 }}>
                    <div>
                        <p>CONFIRMATION</p>
                    </div>
                    <div>
                        <p className='text-warning'>WITHIN 24 HOURS</p>
                    </div>
                </div>
                <div className='d-flex justify-content-between' style={{ marginTop: 10 }}>
                    <div>
                        <p>MINIMUM WITHDRAW</p>
                    </div>
                    <div>
                        <p className='text-warning'>3$</p>
                    </div>
                </div>
            </div>
            <Toaster/>
        </div>


    );
}

export default Withdraw;
