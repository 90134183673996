import Home from './views/home';
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Login from './components/login/Login';
import Register from './components/register/Register';
import MainWrapper from "./layouts/MainWrapper"
import PrivateRoute from './layouts/PrivateRoute';
import Deposit from './components/deposit/Deposit';
import MainLayout from './layouts/MainLayout';
import Dashboard from './components/dashboard/Dashboard';
import Profil from './components/profile/Profil';
import Team from './components/team/Team';
import Trade from './components/trade/Trade';
import Withdraw from './components/withdraw/Withdraw';
import Support from './components/Support/Support';
import Detail from './components/detail/Detail';
import Neodash from './components/dashboard/Neodash';
import Send from './components/send/Send';
function App() {
  return (
    <BrowserRouter>
      <MainWrapper >
      <Routes>
        <Route path='/' element={<Login />} />
        <Route path='/login' element={<Login />} />
        <Route path='/register' element={<Register />} />
        <Route path='/user' element={<MainLayout />}>
          <Route path='dashboard' element={<Neodash />} />
         
          <Route path='deposit' element={ <MainWrapper ><Deposit /> </MainWrapper>} />
          <Route path='profile' element={<Profil />} />
          <Route path='team' element={<Team />} />
          <Route path='trade' element={<Trade />} />
          <Route path='withdraw' element={<Withdraw />} />
          <Route path='support' element={<Support />} />
          <Route path='send' element={<Send />} />
          <Route path='detail' element={<Detail />} />
        </Route>
      </Routes>
      </MainWrapper >
    </BrowserRouter>

  );
}

export default App;
