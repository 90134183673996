import React, { useRef, useEffect, useState } from 'react';
import toast, { Toaster } from 'react-hot-toast';

const Copy = ({ text }) => {
    const textRef = useRef(null);
    const handleCopy = () => {
        textRef.current.select();
        navigator.clipboard.writeText(textRef.current.value)
          .then(() => {
            toast.success('Copied successfully');
          })
          .catch((error) => {
            toast.error('Failed to copy');
            console.error('Copy failed:', error);
          });
      };

    return (
        <div>
            <input type="hidden" ref={textRef} value={text} readOnly />
            <a href="#" onClick={(e) => {
                e.preventDefault()
                handleCopy()
            }}>
                <ion-icon name="copy-outline"></ion-icon>
            </a>
            <Toaster />
        </div>
    );
};

export default Copy;