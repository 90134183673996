import React from 'react';

const Summary = (props) => {
    return (
        <div className='card' style={{ backgroundColor: 'black', paddingInline: 40, paddingBottom: 20, paddingTop: '20px', height: 300, borderRadius: '6px' }}>
            <div className='d-flex justify-content-start'>
                <div >
                    <img src={props?.data?.profile} className='profile-img' />
                </div>
                <div style={{ marginLeft: 20 }}>
                    <p className='text-white text-center'>{props?.data?.username}</p>
                </div>
            </div>
            <div className='d-flex justify-content-between' style={{ marginTop: '10%' }}>
                <div >
                    <p className='text-white text-center'>Deposit</p>
                    <p className='text-white text-center'>{props?.data?.active ? "2$" : 0}</p>
                </div>
                <div >
                    <p className='text-white text-center'>Commission</p>
                    <p className='text-white text-center'>{props?.data?.active ? "2$" : 0.25}</p>
                </div>
            </div>
        </div>
    );
}

export default Summary;
