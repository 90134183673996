import React, { useEffect, useState } from 'react';
import './neo.css'
import { Userbalance } from '../../utils/balance';
import { Link } from 'react-router-dom'
import { History } from '../../utils/history';
import useAxios from '../../utils/useAxios';
import { base_url } from '../config/config';
import Modal from 'react-responsive-modal';
import toast, { Toaster } from 'react-hot-toast';
import { CircularProgressbar } from 'react-circular-progressbar';
const Neodash = () => {
    const [balance, setBalance] = useState(0)
    const [withdraw, setwithDraw] = useState(0)
    const [revnue, setRevnue] = useState(0)
    const [todayrevnue, setToadayRevnue] = useState(0)
    const [wallet, setWallet] = useState('')
    const [showwallet, setShowallet] = useState('')
    const [show, setShow] = useState(false);
    const [show1, setShow1] = useState(false);
    const [orderId, setOrderId] = useState(null)
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const api = useAxios()
    const [history, setHistory] = useState([])
    const getBalance = async () => {
        let data = await Userbalance()
        console.log(data)
        setBalance(data.balance)
        setRevnue(data.earning)
        setwithDraw(data.withdraw)
        setToadayRevnue(data.today_earning)
        const hdata = await History()
        setHistory(hdata)
        try {
            const data = await api.get(`${base_url}/wallet/`)
            const originalString = data.data.wallet
            const lastTwoElements = originalString.slice(-3);
            const fisrt_elem = originalString.slice(0, 4)
            console.log(data.data)
            setShowallet(`${fisrt_elem}...${lastTwoElements}`)
            setWallet(originalString)
        } catch (error) {
            console.log(error)
        }

    }
    const changewallet = async () => {
        try {
            const data = await api.post(`${base_url}/wallet/`, { wallet: wallet })
            toast.success("wallet changed successfully")
            setShow(false)
            console.log(data)
            const originalString = wallet
            const lastTwoElements = originalString.slice(-3);
            const fisrt_elem = originalString.slice(0, 4)
            console.log(fisrt_elem, originalString)
            setShowallet(`${fisrt_elem}...${lastTwoElements}`)
            setWallet(originalString)
        } catch (error) {
            toast.error("error while changin g wallet")
        }
    }
    useEffect(() => {
        getBalance()
    }, [])
    return (
        <div className='d-flex justify-content-center' style={{ marginTop: '100px', marginBottom: '100px' }}>
            <div className='wrper-wallet ' >
                <div>
                    <div className='d-flex justify-content-center' >
                        <div className="card-BBF">

                            <div className='d-flex justify-content-between' style={{ marginInline: "8%", marginTop: "50px" }}>
                                <div>
                                    <pre>
                                        <p className='text-center  text-warning d-tag'>{balance?.toFixed(2)}$</p>
                                        <h3 className='text-center  text-warning d-tag'>BALANCE</h3>
                                    </pre>
                                </div>
                                <div>
                                    <pre>
                                        <p className='text-center  text-danger d-tag'>-{withdraw?.toFixed(2)}$</p>
                                        <h3 className='text-center  text-danger d-tag'>WITHDRAW</h3>
                                    </pre>
                                </div>
                                <div>
                                    <pre>
                                        <p className='text-center  text-success d-tag'>{revnue?.toFixed(2)}$</p>
                                        <h3 className='text-center  text-success d-tag'>ERANING</h3>
                                    </pre>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div className='d-flex justify-content-center' >
                        <div className="card-BBF">

                            <div className='d-flex justify-content-between' style={{ marginInline: "20%", marginTop: "50px" }}>
                                <div>

                                    <div>
                                        <div className='d-flex justify-content-center' style={{ height: 50, width: 50, marginTop: '-20px', marginLeft: '30px' }}>
                                            <div>
                                                <CircularProgressbar value={10} text={`10%`} />
                                            </div>
                                        </div>
                                        <h3 className='text-warning d-tag'>Completed</h3>
                                    </div>

                                </div>
                                <div>
                                    <pre>
                                        <p className='text-center  text-white d-tag'>{showwallet}</p>
                                        <h3 className='text-center  text-danger d-tag'><a href="#" onClick={(e) => {
                                            e.preventDefault()
                                            handleShow()
                                        }} style={{ textDecoration: 'none', color: 'white' }}><ion-icon name="create-outline"></ion-icon>Account</a></h3>
                                    </pre>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='d-flex justify-content-center'>
                    <div>
                        <div className="balance-PEh" style={{ marginBottom: 0 }}>
                            <div className='justify-content-center'>
                                <div>
                                    <p className='text-center'>
                                        Current Balance
                                    </p>
                                </div>
                            </div>
                            <p className="item-24608421-3q3 text-center">${balance?.toFixed(2)}</p>
                        </div>
                        <div className="buttons-mW9" >
                            {/* <Link to='/user/send' style={{ textDecoration: "none", fontSize: '15px' }} className="button-XVK">Send</Link> */}
                            <Link to='/user/profile' style={{ textDecoration: "none", fontSize: '15px' }} className="button-tUH">Invite</Link>
                        </div>
                    </div>
                </div>
                <div className="transaction-Q5F">
                    <p className="text-center" style={{ margin: '20px' }}>Transaction History</p>
                    <div className="list-Ld7 hide-scrollbar" style={{ overflow: "auto", height: '300px' }}>
                        {history.map((i, j) => {
                            return (
                                <div className="transaction-6cH" key={j}>

                                    <div className='d-flex justify-content-between'>
                                        <div className="d-flex justify-content-start" style={{ marginLeft: '-20px', marginTop: '10px', marginBottom: '10px' }}>
                                            <div style={{ marginInline: '10px' }}>
                                                <img className="crypto-9qT" src="/assets/images/coin-3.png" height={50} width={50} />
                                            </div>
                                            <div className="crypto-5DK">
                                                <div className="bitcoin-3A9">{i.amount}$</div>

                                            </div>
                                        </div>
                                        <div style={{ marginInline: "20px", marginTop: '10px' }}>
                                            <p className={i.confirmed ? 'text-success' : 'text-warning'} style={{ fontSize: 10 }} >
                                                {i.confirmed ? 'Conirmed' : 'Pending'}
                                            </p>
                                            <p className='text-sucess' style={{ fontSize: 10 }} >{i.date?.slice(2, 10)}</p>
                                        </div>
                                        <div>
                                            <p className='text-sucess' style={{ fontSize: "10px !important", marginTop: '10px' }}>{i.type}</p>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
            <div>
                <Modal open={show} onClose={handleClose} center styles={{ borderRadius: '20px !important', border: '1px solid black' }}>
                    <div className='d-flex justify-content-center'>
                        <div className="form-w">
                            <div>

                                <div style={{ width: "100%", fontSize: '11px' }}>
                                    <p className='text-center'>{wallet}</p>
                                </div>
                                <p className='text-warning' style={{ fontSize: '11px' }}>
                                    Add bnb or Polygon wallet only and you will
                                    recieve On one network
                                </p>
                            </div>
                            <div className="input-w">
                                <div className="inputBox-w">
                                    <input type="string" placeholder="Change withdraw wallet" onChange={(e) => {
                                        setWallet(e.target.value)
                                    }} />
                                </div>
                                <div className="inputBox-w">
                                    <input type="submit" defaultValue="Submit" onClick={(e) => {
                                        changewallet()
                                    }} />
                                </div>
                            </div>

                        </div>
                    </div>
                </Modal>
                <Toaster />
            </div>
        </div>


    );
}

export default Neodash;
