import { useState, useEffect } from 'react';
import '../login/Login.css'
import { useAuthStore } from '../../store/auth';
import Header from '../header/Header';
import { Link } from 'react-router-dom';
import { register } from '../../utils/auth';
import { useNavigate } from 'react-router-dom';
import { Modal } from 'react-responsive-modal';
import toast, { Toaster } from 'react-hot-toast';
import axios from 'axios';
import { base_url } from '../config/config';
const Register = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [password2, setPassword2] = useState('');
    const [email, setEmail] = useState('');
    const [refral, setRefral] = useState('');
    const [show, setShow] = useState('')
    const isLoggedIn = useAuthStore((state) => state.isLoggedIn);
    const navigate = useNavigate();
    const handleClose = () => setShow(false)
    const [code, setCode] = useState('')
    const [loading, setLoading] = useState(false)
    const [confirmed, setConfirmed] = useState(false)

    useEffect(() => {
        if (isLoggedIn()) {
            navigate('/');
        }
    }, []);

    const resetForm = () => {
        setUsername('');
        setPassword('');
        setPassword2('');
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true)
        let ref = decrypt(refral)
        console.log(ref)
        const { error } = await register(username, password, password2, email, ref);
        if (error) {
            toast.error(JSON.stringify(error));
        } else {
            navigate('/');
            resetForm();
            window.location.reload();
        }
        setLoading(false)
    };

    const sendCode = async (e) => {
        if (confirmed) {
            await handleSubmit(e)
        }
        else {
            try {
                if (!confirmed) {
                    e.preventDefault()
                    let code = await axios.post(`${base_url}/email/`, { email: email })
                    setConfirmed(true)
                }
                else {
                    setShow(true)
                }

            } catch (error) {
                toast.error(`${error?.message}`)
                console.log(error)
            }
        }

    }

    const ConfirmEmail = async (e) => {
        try {
            e.preventDefault()
            setShow(true)
            let codeR = await axios.post(`${base_url}/email/confirm/`, { code: code, email: email })
            await handleSubmit(e)
        } catch (error) {
            toast.error(`${error?.message}`)
        }

    }
    function decrypt(encrypted) {
        let key = "baibars313"
        var decryptedString = atob(encrypted);
        if (decryptedString.startsWith(key)) {
            var originalString = decryptedString.substring(key.length);
            return parseInt(originalString, 10);
        }
        return null; // Invalid or corrupted data
    }

    useEffect(() => {
        // Function to get query parameters from the URL
        const getQueryParam = (name) => {
            const urlSearchParams = new URLSearchParams(window.location.search);
            return urlSearchParams.get(name);
        };

        // Get the 'referral' query parameter from the URL
        const referralFromUrl = getQueryParam('refral');

        // Update state with the referral code
        if (referralFromUrl) {
            setRefral(referralFromUrl);
        }
    }, [])
    return (
        <>
            <Header />
            <div id="container-login">
                <div id="title">
                    <i className="material-icons lock">lock</i> Login
                </div>
                {!confirmed ?
                    <>
                        <form onSubmit={(e) => {
                            handleSubmit(e)
                        }}>

                            <div className="input" style={{ display: 'flex', justifyContent: 'start' }}>
                                <div className="input-addon">
                                    <i className="material-icons">email</i>
                                </div>
                                <div>
                                    <input id="emaile" placeholder="Email" type="email" required className="validate input-cs" autoComplete="off" onChange={(e) => setEmail(e.target.value)} style={{ marginLeft: '10px' }} value={email} />
                                </div>
                            </div>
                            <div className="input" style={{ display: 'flex', justifyContent: 'start' }}>
                                <div className="input-addon">
                                    <i className="material-icons">face</i>
                                </div>
                                <div>
                                    <input id="username" placeholder="Username" type="text" required className="validate input-cs" autoComplete="off" onChange={(e) => {
                                        setUsername(e.target.value)
                                    }} value={username} style={{ marginLeft: '10px' }} />
                                </div>
                            </div>
                            <div className="clearfix" />
                            <div className="input" style={{ display: 'flex', justifyContent: 'start' }}>
                                <div className="input-addon">
                                    <i className="material-icons">vpn_key</i>
                                </div>
                                <div>
                                    <input id="password" placeholder="Password" type="password" onChange={(e) => {
                                        setPassword(e.target.value)
                                    }} value={password} required className="validate input-cs" autoComplete="off" style={{ marginLeft: '10px' }} />
                                </div>
                            </div>
                            <div className="clearfix" />
                            <div className="input" style={{ display: 'flex', justifyContent: 'start' }}>
                                <div className="input-addon">
                                    <i className="material-icons">vpn_key</i>
                                </div>
                                <div>
                                    <input id="password" placeholder="Confirm Password" onChange={(e) => {
                                        setPassword2(e.target.value)
                                    }} value={password2} type="password" required className="validate input-cs" autoComplete="off" style={{ marginLeft: '10px' }} />
                                </div>
                            </div>
                            <div className="input" style={{ display: 'flex', justifyContent: 'start' }}>
                                <div className="input-addon">
                                    <ion-icon name="person-add-outline"></ion-icon>
                                </div>
                                <div>
                                    <input id="refral" placeholder="refral code" onChange={(e) => {
                                        setRefral(e.target.value)
                                    }} type="text" required className="validate input-cs" value={refral} autoComplete="off" style={{ marginLeft: '10px' }} />
                                </div>
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <div className="remember-me" style={{ display: 'flex', justifyContent: 'start' }}>
                                    <div>
                                        <input type="checkbox" className='input-cs' />
                                    </div>
                                    <div><span style={{ color: '#DDD' }}>Remember Me</span></div>
                                </div>
                            </div>
                            <input type="submit" className='input-cs' defaultValue="Log In" style={{ height: 35, width: 100, fontSize: 12 }} />
                        </form>
                    </> :
                    <form onSubmit={(e) => ConfirmEmail(e)}>
                        <div className="input" style={{ display: 'flex', justifyContent: 'start' }}>
                            <div>
                                <input id="username" placeholder="confirmation code" type="text" required className="validate input-cs" autoComplete="off" onChange={(e) => {
                                    setCode(e.target.value)
                                }} style={{ marginLeft: '10px' }} />
                            </div>
                        </div>
                        <div className="clearfix" />
                        <button type="submit" disabled={loading} className='input-cs' style={{ height: 35, width: 100, fontSize: 12, backgroundColor: 'green' }} >{loading ? "wait" : "submit"}</button>
                    </form>
                }

                <div style={{ display: 'flex', justifyContent: 'space-between', marginInline: '20px', marginTop: 20 }}>
                    <div>
                        <a href="#" onClick={() => {
                            
                        }}>Already Have an account?</a>
                    </div>
                    <div  >
                        <Link to="/login" className='text-success' style={{ textAlign: 'end' }}>Login</Link>
                    </div>
                </div>
                <Toaster />

            </div>
        </>
    );
}

export default Register;
