import React, { useEffect, useState } from 'react';
import './profile.css'
import Copy from '../copy/Copy';
import useAxios from '../../utils/useAxios';
import { base_url } from '../config/config';
import { useAuthStore } from '../../store/auth';
import { UserProfile } from '../../utils/Userprofile';
import { Userbalance } from '../../utils/balance';
const Profil = () => {
    const [isLoggedIn, user] = useAuthStore((state) => [
        state.isLoggedIn,
        state.user,
    ]);
    const [balance, setBalance] = useState(0)
    const [profile, setProfile] = useState('')
    const getBalance = async () => {
        let data = await Userbalance()
        console.log(data)
        setBalance(data.balance)
        let userData = await UserProfile()
        setProfile(userData)
    }

    const api = useAxios()
    const [refral, setRefral] = useState('')
    const [refrallink, setrefrallink] = useState('')
    useEffect(() => {
        if (isLoggedIn()) {
            try {
                getBalance()
                let data = api.get(`${base_url}/check`).then((res) => {
                    let ref = encrypt(parseInt(res.data.user_id))
                    setRefral(ref)
                    console.log(ref)
                    const baseUrl = window.location.origin;
                    setrefrallink(`${baseUrl}/register?refral=${ref}`)

                })

            } catch (error) {

            }
        }
    }, [])
    // Function to encrypt an integer to a random string
    function encrypt(integer) {
        let key = "baibars313"
        var originalString = integer.toString();
        var encryptedString = btoa(key + originalString);
        return encryptedString;
    }

    // Function to decrypt the random string back to an integer



    return (
        <div className='body-p'>
            <input id="slider" className="customSlider" type="checkbox" defaultChecked style={{ display: "none", opacity: 0 }} />
            <label htmlFor="slider" />
            <div className="wrapper-p">
                <div className="top-icons">
                    <i className="fas fa-long-arrow-alt-left" />
                    <i className="fas fa-ellipsis-v" />
                    <i className="far fa-heart" />
                </div>
                <div className="profile">
                    <img src={profile?.profile_photo} className="thumbnail" />
                    <div className="check"><i className="fas fa-check" /></div>
                    <h3 className="name">{profile?.useranme}</h3>
                    <p className="title">{profile?.blocked ? 'Blocked' : 'Active'}</p>
                    <p className="description">{profile?.email}</p>
                    <a href="#demo-modal" type="button" style={{ textDecoration: 'none', textAlign: 'center' }} className="btn">Invite</a>
                </div>
                {/* <div style={{ display: 'flex', justifyContent: 'start' }}>
                    <div className='c-opt'  >
                        <a href="" style={{ textDecoration: 'none' }}><ion-icon name="person-outline"></ion-icon>Edit Profile</a>
                    </div>
                </div>
                <div style={{ display: 'flex', justifyContent: 'start' }}>

                    <div className='c-opt'  >
                        <a href="" style={{ textDecoration: 'none' }}> <ion-icon name="key-outline"></ion-icon>Change Password</a>
                    </div>

                </div>
                <div style={{ display: 'flex', justifyContent: 'start' }}>
                    <div className='c-opt'  >
                        <a href="" style={{ textDecoration: 'none' }}><ion-icon name="cash-outline"></ion-icon>Reward</a>
                    </div>
                </div> */}
            </div>
            <div id="demo-modal" className="modal">
                <div className="modal__content" style={{ overflow: 'auto', height: '90%', paddingTop: '80px', paddingBottom: '80px' }} >
                    <h2 className='text-white text-center' style={{ marginBottom: "0px !important" }}>Referral</h2>
                    <p className='text-center'>
                        🌟 Unlock Rewards with Our Referral Program! 🌈

                        Join our community and start earning commissions by sharing your unique referral code. As a referrer, you'll enjoy commission perks at multiple levels:
                    </p>
                    <ul>
                        <li><p class="text-white">Experience unparalleled growth with our revolutionary platform!</p></li>
                        <li><p class="text-white">Join by paying a minimal joining fee, and watch your earnings soar through direct and indirect referrals up to level seven.</p></li>
                        <li><p class="text-white">Our automated marketing and networking system ensures rewards for every user you bring in – enjoy $0.25 for each referral and lucrative bonuses up to $10,000 on every level completion.</p></li>
                        <li><p class="text-white">Let the system work for you, ensuring rewards even when you're not actively participating.</p></li>
                        <li><p class="text-white">Don't miss out – unlock your financial success with us today!</p></li>
                    </ul>


                    <p className='text-center'>
                        ✨ Share the code, spread the joy! ✨
                    </p>
                    <p className='text-white text-center'>
                        Copy Full: {refral}
                    </p>
                    <div className='d-flex justify-content-center'>
                        <div>
                            <Copy text={`🌟 Unlock Rewards with Our Referral Program! 🌈 Join our community and start earning commissions by sharing your unique referral code. As a referrer, you'll enjoy commission perks at multiple levels:

                                        Experience unparalleled growth with our revolutionary platform!
                                        
                                        Join by paying a minimal joining fee, and watch your earnings soar through direct and indirect referrals up to level seven.
                                        
                                        Our automated marketing and networking system ensures rewards for every user you bring in – enjoy $0.25 for each referral and lucrative bonuses up to $10,000 on every level completion.
                                        
                                        Let the system work for you, ensuring rewards even when you're not actively participating.
                                        
                                        Don't miss out – unlock your financial success with us today!
                                        
                                        ✨ Share the code, spread the joy! ✨
                                        
                                        ${refrallink}
                                        `} />
                        </div>
                    </div>
                    <p className='text-white text-center' style={{ marginTop: '10px' }}>
                       Copy Link
                    </p>
                    <div className='d-flex justify-content-center'>
                        <div>
                            <Copy text={refrallink} />
                        </div>
                    </div>
                    <a href="#" className="modal__close">×</a>
                </div>
            </div>

        </div>

    );
}

export default Profil;
