import React, { useEffect, useState } from 'react';
import './tababr.css'
import { Link } from 'react-router-dom';
import { logout } from '../../utils/auth';
import { useNavigate } from 'react-router-dom';
const Tabbar = () => {
    const navigate = useNavigate()
    const url = window.location.pathname
    const [view, setView] = useState(0)
    useEffect(() => {
        let url = window.location.pathname
        console.log(url === '/user/trade')

        if (url === '/user/dashboard') {
            setView(1)
        }
        if (url === '/user/deposit') {
            setView(2)
        }
        if (url === '/user/withdraw') {
            setView(3)
        }
        if (url === '/user/team') {
            setView(4)
        }
        if (url === '/user/profile') {
            setView(5)
        }
        if (url === '/user/support') {
            setView(6)
        }
        if (url === '/user/logout') {
            setView(7)
        }

    }, [url])
    return (

        <div style={styles.bottomNav}>

            <div style={styles.navItem}>
                <Link className="tab-link" to="/user/deposit">
                    <div className={view === 2 ? 'active-tab' : ''} onClick={() => setView(2)} >
                        <img className='icon-mg' src="https://cdn.pixabay.com/photo/2013/07/12/14/07/bag-147782_1280.png" alt="" />

                    </div>
                    {view === 2 &&
                        <p style={{ textAlign: 'center' }}>Deposit</p>
                    }
                </Link>
            </div>
            <div style={styles.navItem}>
                <Link className="tab-link" to="/user/withdraw">
                    <div className={view === 3 ? 'active-tab' : ''} onClick={() => setView(3)}  >
                        <img className='icon-mg' src="/assets/images/withdraw.png" alt="" />
                    </div>
                    {view === 3 &&
                        <p style={{ textAlign: 'center' }}>Withdraw</p>
                    }
                </Link>
            </div>

            <div style={styles.navItem}>
                <Link className="tab-link" to="/user/team">
                    <div className={view === 4 ? 'active-tab' : ''} onClick={() => setView(4)} >
                        <img className='icon-mg' src="/assets/images/team.png" alt="" />
                    </div>
                    {view === 4 &&
                        <p style={{ textAlign: 'center' }}>Team</p>
                    }
                </Link>
            </div>
            <div style={styles.navItem}>
                <Link className="tab-link" to="/user/dashboard">
                    <div className={view === 1 ? 'active-tab' : ''} onClick={() => setView(1)} >
                        <img className='icon-mg ' src="https://cdn.pixabay.com/photo/2013/07/12/14/48/building-148837_1280.png" alt="" />
                    </div>
                    {view === 1 &&
                        <p style={{ textAlign: 'center' }}>Home</p>
                    }
                </Link>
            </div>
            <div style={styles.navItem}>
                <Link to='/user/profile' className="tab-link">
                    <div className={view === 5 ? 'active-tab' : ''} onClick={() => setView(5)} >
                        <img className='icon-mg' src="/assets/images/trade.png" alt="" />
                    </div>
                    {view === 5 &&
                        <p style={{ textAlign: 'center' }}>Profile</p>
                    }
                </Link>
            </div>
            <div style={styles.navItem}>
                <Link className="tab-link" to="/user/support">
                    <div className={view === 6 ? 'active-tab' : ''} onClick={() => setView(6)} >
                        <img className='icon-mg' src="/assets/images/support.png" alt="" />
                    </div>
                    {view === 6 &&
                        <p style={{ textAlign: 'center' }}>Support</p>
                    }
                </Link>
            </div>
            <div style={styles.navItem}>
                <a className="tab-link" href="#" onClick={(e) => {
                    e.preventDefault()
                    logout()
                    window.location.href = "/login"
                }}>
                    <div className={view === 7 ? 'active-tab' : ''} onClick={() => setView(7)}  >
                        <img className='icon-mg' src="https://cdn.pixabay.com/photo/2016/03/31/19/48/exit-1295304_1280.png" alt="" />
                    </div>
                    {view === 7 &&
                        <p style={{ textAlign: 'center' }}>Logout</p>
                    }
                </a>
            </div>
        </div>
    );
};

const styles = {
    bottomNav: {
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
        position: 'fixed',
        bottom: 0,
        left: 0,
        width: '100%',
        background: '#333',
        color: 'white',
        padding: '10px',
        height: '70px'
    },
    navItem: {
        textDecoration: 'none',
        color: 'white',
        cursor: 'pointer',
        fontSize: '12px',

    },
};
export default Tabbar;
