import useAxios from "./useAxios";
import { base_url } from "../components/config/config";
export const History = async () => {
    const api = useAxios()
    try {
        const netuser = await api.get(`${base_url}/history/`)
        return netuser?.data
    } catch (error) {
        return []
    }


}