import React, { useEffect, useState } from 'react';
import './deposit.css'
import useAxios from '../../utils/useAxios';
import { useAuthStore } from '../../store/auth';
import Copy from '../copy/Copy'
import toast, { Toaster } from "react-hot-toast"
import { getLink } from '../../utils/getLink';
const Deposit = () => {
    const [wallet, setWallet] = useState('')
    const api = useAxios()
    const [imageLink, setImageLink] = useState('')
    const loggedIn = useAuthStore((state) => state.isLoggedIn)();
    const [loading, setLoading] = useState(false)
    const handleSubmit = async () => {
        setLoading(true)
        try {
            await api.post("/deposits/", { screen_shot: imageLink })
            setLoading(false)
            toast.success("deposit recipt recieved successfully")
        } catch (error) {
            setLoading(false)
            toast.error("server error or check file upladed")

        }

    }
    useEffect(() => {
        if (loggedIn) {

        }
        console.log(loggedIn)
    }, [])
    return (
        <>
            <div className="container-c " style={{ margin: "20% !important" }}>
                <div className="gradient-cards">
                    <div className="card">
                        <div className="container-card bg-green-box">
                            <div style={{ justifyContent: 'center', display: 'flex' }}>
                                <div>
                                    <svg width="80" height="80" viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect x="1" y="1" width="118" height="118" rx="24" fill="url(#paint0_linear_1366_4547)" fillOpacity="0.15" stroke="url(#paint1_radial_1366_4547)" strokeWidth="2" />
                                        <circle cx="60" cy="60" r="45" fill="#26A17B" />
                                        <text x="50%" y="50%" dominantBaseline="middle" textAnchor="middle" fontSize="24" fill="#ffffff">USDT</text>
                                        <defs>
                                            <linearGradient id="paint0_linear_1366_4547" x1="0.0063367" y1="0.168432" x2="120.853" y2="119.009" gradientUnits="userSpaceOnUse">
                                                <stop stopColor="#2FCB89" stopOpacity="0.7" />
                                                <stop offset="0.489583" stopColor="#2FCB89" stopOpacity="0" />
                                                <stop offset="1" stopColor="#2FCB89" stopOpacity="0.7" />
                                            </linearGradient>
                                            <radialGradient id="paint1_radial_1366_4547" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(60 60) rotate(96.8574) scale(122.674 149.921)">
                                                <stop stopColor="#54E8A9" />
                                                <stop offset="1" stopColor="#1A3E31" stopOpacity="0.2" />
                                            </radialGradient>
                                        </defs>
                                    </svg>
                                </div>
                            </div>

                            <p style={{ fontSize: 14, textAlign: 'center' }}>Here Is Your Deposit Address</p>
                            <p className="" style={{ fontSize: 12, textAlign: 'center' }}>
                                TMDBWndHngboEzmfdk6pZSjLKgnPNB3xj1
                            </p>
                            <div style={{ justifyContent: 'center', display: 'flex' }}>
                                <div >
                                    <Copy text={"TMDBWndHngboEzmfdk6pZSjLKgnPNB3xj1"} />
                                </div>
                            </div>
                            <div className='d-flex justify-content-center'>

                                <div className="form-w">
                                    <p className='text-center'>Upload Transaction Proof </p>
                                    <div className="input-w">
                                        <div className="inputBox-w">
                                            <input type="file" onInput={async (e) => {
                                                setLoading(true)
                                                const link = await getLink(e)
                                                setImageLink(link)
                                                setLoading(false)
                                            }} />
                                        </div>
                                        <div className="inputBox-w">
                                            <input type="submit" value={!loading ? "submit" : "wait"} disabled={loading} onClick={handleSubmit} />
                                        </div>
                                    </div>
                                </div>
                                <Toaster />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='card-dep'>
                <div className="container-list">
                    <h4 className='' style={{ textAlign: 'center' }}> Read </h4>
                    <ol className='instructions'>
                        {/* <li>This is a <span className='text-white'> BNB chain , TRC20</span> and <span className='text-white'>Polygon network</span> wallet. You can only deposit USDT.</li> */}
                        <li>The minimum deposit is $2.only <span className='text-white'>TRC20 USDT</span></li>
                        <li>Your deposit will be confirmed within 24 hours.</li>
                        <li>Check the address twice before making a transaction.</li>
                        <li>Upload Transaction Reciept up there </li>
                    </ol>
                </div>
            </div>

        </>





    );
}

export default Deposit;
