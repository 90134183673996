
import useAxios from "./useAxios";
import { API_BASE_URL } from "./constants";
export const Userbalance = async () => {
    try {
        const api = useAxios()
        const response = await api.get(`${API_BASE_URL}/bal/`);
        console.log(response.data,"________________________________________________________")
        let data = {
            balance: response.data.current_balance,
            withdraw: response.data.withdrawal,
            earning: response.data.total_revenue,
            level_1_total:response.data.level_1_total,
            level_2_total:response.data.level_2_total,
            level_3_total:response.data.level_3_total,
            level_4_total:response.data.level_4_total,
            level_5_total:response.data.level_5_total,
            level_6_total:response.data.level_6_total,
            level_7_total:response.data.level_7_total,
            today_earning:response.data.today_earning
        }
        
        return data
    } catch (error) {
    console.log(error)
        let data = {
            balance: 0,
            withdraw: 0,
            earning: 0
        }
        return data
    }
}