import React, { useEffect, useState } from 'react';
import './naavbar.css'
import { Link } from 'react-router-dom';
import { Userbalance } from '../../utils/balance';
import { UserProfile } from '../../utils/Userprofile';
import { ethers } from "ethers";
import useAxios from '../../utils/useAxios';
import { base_url } from '../config/config';
import { useAuthStore } from '../../store/auth';
const Navbar = () => {
    const [balance, setBalance] = useState(0)
    const [profile, setProfile] = useState('')
    const [wallet, setWallet] = useState('')
    const [userName, setUsername] = useState('')
    const api = useAxios()
    const [bnb, setBnb] = useState('')
    const [loading,setLoadingBal]=useState(false)
    const [polygon, setPolygon] = useState('')
    const [Limit, setLimit] = useState(0)
    const rpc_polygon = "https://polygon-mainnet.g.alchemy.com/v2/S30ZpRpeJpogqbaZMV33NTlELXTVwcTQ"
    const rpc_bnb = "https://small-wider-lake.bsc.discover.quiknode.pro/ec7c5fa19861c143537ab3bb58544ae9fcf65237/"
    const loggedIn = useAuthStore((state) => state.isLoggedIn)();
    const getBalance = async () => {
        let data = await Userbalance()
        console.log(data)
        setBalance(data.balance)
        let userData = await UserProfile()
        setProfile(userData.profile_photo)
        setUsername(userData.username)

    }


    const checkBalancebnb = async (address, token, rpc) => {
        const provider = new ethers.providers.JsonRpcProvider(rpc);
        const tokenContract = new ethers.Contract(
            token,
            ['function balanceOf(address) view returns (uint)'], provider

        );


        try {
            const balance = await tokenContract.balanceOf(wallet);
            let bal = parseFloat(balance.toString()) / 1000000;
            console.log(bal)
            if (bal >= Limit) {
                const data = await api.get(`${base_url}/make_deposit/`)
                console.log(data)
            }
        } catch (error) {
            // window.location.reload()
            console.log('Error checking balance:', error);
        }

    }

    const getWallet = async () => {
        try {
            const data = await api.get(`${base_url}/deposit_wallet/`)

            if (data.data.wallet !== undefined && loggedIn) {
                setWallet(data.data.wallet)
                setLimit(data.data.limit)
                setBnb(data.data.bnb_token)
                setPolygon(data.data.poly_token)
            }
        } catch (error) {

        }
    }
    const checkBalance = async () => {
        setLoadingBal(true)
        await checkBalancebnb(wallet, bnb, rpc_bnb)
        await checkBalancebnb(wallet, polygon, rpc_polygon)
        await getBalance()
        setLoadingBal(false)
    }

    useEffect(() => {
        getWallet()
        getBalance()
    }, [wallet])
    return (
        <nav className='navbar-b'>
            <div>
                <Link to="/user/profile" className='tab-link'>
                    <img className='logo' style={{ width: 50, height: 50, border: "1px solid white", borderRadius: '50%' }} src={profile} alt="" />
                    <span style={{ marginInline: '10px' }}>{userName}</span>
                </Link>

            </div>

            <div className='Balance' style={{ fontSize: 20, padding: 7, border: "none", borderRadius: '20px', fontWeight: 600 }}>
                <p className="inline-content" style={{ textAlign: 'start', marginRight: '10px' }}>
                <a href="#" onClick={(e)=>{
                e.preventDefault()
                checkBalance()
                }}> <ion-icon name="refresh"></ion-icon></a>
                    <img
                        className="logo"
                        style={{ width: 20, height: 20, border: "1px solid white", borderRadius: '50%', marginRight: '10px' }}
                        src="/assets/images/coin-3.png"
                        alt=""
                    />{!loading?<>{balance?.toFixed(2)}</>:'loading'}
                </p>

            </div>
        </nav>
    );
}

export default Navbar;
